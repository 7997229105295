.btn-sm {
    box-shadow: none;
    outline: none;
    padding: 6px 10px;
    border: none;
    border-radius: 5px;
}

td.actions {
    display: flex;
    align-items: center;
    gap: 10px;

    .btn-sm {
        svg {
            width: 17px;
        }
    }
}

.accordion-button:focus {
    box-shadow: none;
}

table {
    td {
        img {
            height: 70px;
            width: 100px;
            object-fit: cover;
            border-radius: 10px;

            &.attachment {
                width: 35px;
                height: 35px;
                object-fit: contain;
            }
        }
    }
}

.long_text {
    width: 70%;
}

.table__add {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.default__pagination {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 50px;

    ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        margin: 0;
        align-items: center;

        li {
            margin: 0 5px;
            padding: 10px 14px;
            line-height: 1;
            border-radius: 4px;
            min-width: 20px;

            &.selected {
                background-color: #368bca;
                color: #fff;
                padding: 10px 14px;
                line-height: 1;
                border-radius: 4px;
            }
        }

        li.previous,
        li.next {
            img {
                height: 24px;
            }
        }
    }
}

.form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #e3e3e3;
    outline: 0;
    box-shadow: none;
}

.select-control {

    .css-13cymwt-control,
    .css-t3ipsp-control {
        border-radius: 10px;
        border-color: #e3e3e3;
        padding: 2px 5px;
        box-shadow: none;

        &:focus {
            border-color: #e3e3e3;
        }
    }

    .css-d7l1ni-option {
        background-color: #e3e3e3;
    }

    .css-tr4s17-option {
        background-color: #e3e3e3;
        color: #333;
    }
}

.cost__table {
    .table_head th {
        background-color: #f3f6f9 !important;
    }

    .table td,
    .table th {
        padding: 10px 10px !important;
        font-weight: 400;
        vertical-align: middle;

        .form-control {
            border: none;
            background-color: #f3f6f9;
            color: #368bca;
        }
    }
}


/* Add this CSS to your stylesheet */
.submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.submenu.navEffect {
    max-height: 500px;
    /* Adjust based on content size */
    transition: max-height 0.3s ease-in-out;
}

.search__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;

    .leftIcon{
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .count {
        max-width: 185px;
        display: flex;
        align-items: center;
        p{
            margin: 0 5px;
        }
    }

    .search {
        position: relative;
        display: flex;
        align-items: center;
        width: 290px;

        svg {
            position: absolute;
            top: 50%;
            right: 6px;
            transform: translate(-50%, -50%);
            width: 20px;
        }
    }

}

.table__formAdd {
    width: 100%;

    .actions {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
    }
}
.download img{
    object-fit: contain;
    height: 20px;
    width: 20px;
    border-radius: 0;
    margin: 0;
}

// RICH EDITOR
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
    border: 1px solid #e4e6ef;

}

.ql-toolbar.ql-snow {
    border-radius: 10px 10px 0 0px;
}

.ql-container.ql-snow {
    border-radius: 0px 0px 10px 10px;
    min-height: 150px;
}

.ql-editor.ql-blank::before {
    font-style: normal;
    font-size: 14px;
}

/* table__filter */
.table__filter {
    .search_date {
        display: flex;
        gap: 10px;

        .form-control {
            width: 100%;
        }
    }
}

.form-control {
    ::placeholder {
        color: #000;
        opacity: 1;
        /* Firefox */
    }

    ::-ms-input-placeholder {
        /* Edge 12-18 */
        color: #000;
    }
}

.css-tr4s17-option {
    background-color: #368bca !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.form-react-select {

    .css-13cymwt-control,
    .css-t3ipsp-control {
        border-color: #e4e6ef;
        border-radius: 10px;
        outline: none;
        box-shadow: none;
        border: 1px solid #e4e6ef;
        padding: 2px 5px;
        color: #333;
    }

    .css-19bb58m,
    .css-1jqq78o-placeholder {
        color: #585c5f;
    }
}

// BUTTONS

.bg-danger.critical {
    background-color: #F44336 !important;
    color: #fff;
}
.critical {
    background-color: #F44336 !important;
    color: #fff !important;
}
table.dataTable thead>tr>th.sorting_asc,
table thead>tr>th.sorting_desc,
table thead>tr>th.sorting,
table thead>tr>td.sorting_asc,
table thead>tr>td.sorting_desc,
table thead>tr>td.sorting {
    cursor: pointer;
    position: relative;
}

.sorting {
    position: relative;
    &::after {
        content: '';
        background-image: url('../public/assets/up.svg');
        background-size: contain; /* Ensure the image fits */
        background-repeat: no-repeat; /* Prevent tiling */
        position: absolute;
        right: 0px;
        bottom: 50%;
        width: 18px; /* Set according to your image size */
        height: 24px; /* Set according to your image size */
        transform: translateY(50%); /* Adjust for centering */
    }
    &::before {
        content: '';
        background-image: url('../public/assets/down.svg');
        background-size: contain; /* Ensure the image fits */
        background-repeat: no-repeat; /* Prevent tiling */
        position: absolute;
        right: 0px;
        bottom: 35%;
        width: 18px; /* Set according to your image size */
        height: 24px; /* Set according to your image size */
        transform: translateY(50%); /* Adjust for centering */
        opacity: .5;
    }
}

.btn.export{
    display: flex;
    align-items: center;
    gap: 10px;
    svg{
        width: 16px;
    }
}