body,
html {
	font-size: 14px;
	width: 100%;
	height: 100%;
	min-height: 100vh
}

html {
	overflow-x: hidden
}

body {
	background-color: #f3f4f7;
	font-weight: 400;
	color: #5b5b5b;
	line-height: 1.6
}

a {
	color: #368bca;
	text-decoration: none
}

a:hover {
	color: #368bca
}

p {
	line-height: 1.6
}

small {
	font-size: 80%;
	font-weight: 400
}

menu {
	padding-left: 0
}

a,
body,
span,
text {
	font-family: Poppins, sans-serif
}

a:active,
a:focus,
a:hover {
	outline: 0 !important;
	text-decoration: none !important
}

.bold,
b,
strong {
	font-weight: 600
}

dt {
	font-weight: 500
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: Poppins, sans-serif
}

.divider {
	display: block;
	content: '';
	width: 100%;
	height: 1px;
	background: #e8e8e8;
	margin: 30px 0
}

.no-s {
	margin: 0 !important;
	padding: 0 !important
}

.no-m {
	margin: 0
}

.no-p {
	padding: 0
}

.m {
	margin: 15px
}

.m-t-xxs {
	margin-top: 5px
}

.m-r-xxs {
	margin-right: 5px
}

.m-b-xxs {
	margin-bottom: 5px
}

.m-l-xxs {
	margin-left: 5px
}

.m-t-xs {
	margin-top: 10px
}

.m-r-xs {
	margin-right: 10px
}

.m-b-xs {
	margin-bottom: 10px
}

.m-l-xs {
	margin-left: 10px
}

.m-t-sm {
	margin-top: 15px
}

.m-r-sm {
	margin-right: 15px
}

.m-b-sm {
	margin-bottom: 15px
}

.m-l-sm {
	margin-left: 15px
}

.m-t-md {
	margin-top: 20px
}

.m-r-md {
	margin-right: 20px
}

.m-b-md {
	margin-bottom: 20px
}

.m-l-md {
	margin-left: 20px
}

.m-t-lg {
	margin-top: 25px
}

.m-r-lg {
	margin-right: 25px
}

.m-b-lg {
	margin-bottom: 25px
}

.m-l-lg {
	margin-left: 25px
}

.m-t-xxl {
	margin-top: 30px
}

.m-r-xxl {
	margin-right: 30px
}

.m-b-xxl {
	margin-bottom: 30px
}

.m-l-xxl {
	margin-left: 30px
}

.m-t-n-xxs {
	margin-top: -5px
}

.m-r-n-xxs {
	margin-right: -5px
}

.m-b-n-xxs {
	margin-bottom: -5px
}

.m-l-n-xxs {
	margin-left: -5px
}

.m-t-n-xs {
	margin-top: -10px
}

.m-r-n-xs {
	margin-right: -10px
}

.m-b-n-xs {
	margin-bottom: -10px
}

.m-l-n-xs {
	margin-left: -10px
}

.m-t-n-sm {
	margin-top: -15px
}

.m-r-n-sm {
	margin-right: -15px
}

.m-b-n-sm {
	margin-bottom: -15px
}

.m-l-n-sm {
	margin-left: -15px
}

.m-t-n-md {
	margin-top: -20px
}

.m-r-n-md {
	margin-right: -20px
}

.m-b-n-md {
	margin-bottom: -20px
}

.m-l-n-md {
	margin-left: -20px
}

.m-t-n-lg {
	margin-top: -25px
}

.m-r-n-lg {
	margin-right: -25px
}

.m-b-n-lg {
	margin-bottom: -25px
}

.m-l-n-lg {
	margin-left: -25px
}

.m-t-n-xxl {
	margin-top: -30px
}

.m-r-n-xxl {
	margin-right: -30px
}

.m-b-n-xxl {
	margin-bottom: -30px
}

.m-l-n-xxl {
	margin-left: -30px
}

.p {
	padding: 15px
}

.p-h-xxs {
	padding-left: 5px;
	padding-right: 5px
}

.p-h-xs {
	padding-left: 10px;
	padding-right: 10px
}

.p-h-sm {
	padding-left: 15px;
	padding-right: 15px
}

.no-p-h {
	padding-left: 0;
	padding-right: 0
}

.no-p-v {
	padding-top: 0;
	padding-bottom: 0
}

.p-h-md {
	padding-left: 20px;
	padding-right: 20px
}

.p-h-lg {
	padding-left: 25px;
	padding-right: 25px
}

.p-h-xxl {
	padding-left: 30px;
	padding-right: 30px
}

.p-v-xxs {
	padding-top: 5px;
	padding-bottom: 5px
}

.p-v-xs {
	padding-top: 10px;
	padding-bottom: 10px
}

.p-v-sm {
	padding-top: 15px;
	padding-bottom: 15px
}

.p-v-md {
	padding-top: 20px;
	padding-bottom: 20px
}

.p-v-lg {
	padding-top: 25px;
	padding-bottom: 25px
}

.p-v-xxl {
	padding-top: 30px;
	padding-bottom: 30px
}

.full-height {
	height: 100% !important
}

.full-width {
	width: 100% !important
}

.w-xxs {
	width: 50px
}

.w-xs {
	width: 90px
}

.w-sm {
	width: 140px
}

.w {
	width: 200px
}

.w-md {
	width: 240px
}

.w-lg {
	width: 280px
}

.w-xl {
	width: 330px
}

.w-xxl {
	width: 370px
}

.w-auto {
	width: auto
}

.h-auto {
	height: auto
}

.no-b {
	border: 0 !important
}

.b {
	border: 1px solid #eaecf3
}

.b-t {
	border-top: 1px solid #eaecf3
}

.b-r {
	border-right: 1px solid #eaecf3
}

.b-b {
	border-bottom: 1px solid #eaecf3
}

.b-l {
	border-left: 1px solid #eaecf3
}

.b-default {
	border-color: #eaecf3
}

.b-primary {
	border-color: #368bca
}

.b-info {
	border-color: #9c6efc
}

.b-success {
	border-color: #6bcac2
}

.b-warning {
	border-color: #ffaf0f
}

.b-danger {
	border-color: #ee6e83
}

.b-2 {
	border-width: 2px
}

.b-3 {
	border-width: 3px
}

.b-4 {
	border-width: 4px
}

.b-n-t {
	border-top: 0
}

.b-n-r {
	border-right: 0
}

.b-n-b {
	border-bottom: 0
}

.b-n-l {
	border-left: 0
}

.no-r {
	border-radius: 0 !important
}

.r {
	border-radius: 15px
}

.r-t-l {
	border-top-left-radius: 15px
}

.r-t-r {
	border-top-right-radius: 15px
}

.r-b-l {
	border-bottom-left-radius: 15px
}

.r-b-r {
	border-bottom-right-radius: 15px
}

.no-r-t-l {
	border-top-left-radius: 0 !important
}

.no-r-t-r {
	border-top-right-radius: 0 !important
}

.no-r-b-l {
	border-bottom-left-radius: 0 !important
}

.no-r-b-r {
	border-bottom-right-radius: 0 !important
}

.bg-primary {
	background-color: #368bca !important
}

.bg-secondary {
	background-color: #e4e6ef !important
}

.bg-success {
	background-color: #6bcac2 !important
}

.bg-danger {
	background-color: #f75d6fd8 !important
}

.bg-warning {
	background-color: #f5af47ea !important
}

.bg-info {
	background-color: #9465fcea !important
}

.bg-light {
	background-color: #e4eff8 !important
}

.bg-dark {
	background-color: #242e44 !important
}

.text-white {
	color: #fff
}

.text-primary {
	color: #368bca !important
}

.text-secondary {
	color: #a5a5a5 !important
}

.text-success {
	color: #6bcac2 !important
}

.text-danger {
	color: #f75d6fd8 !important
}

.text-warning {
	color: #f5af47ea !important
}

.text-info {
	color: #9465fcea !important
}

.text-light {
	color: #e4eff8 !important
}

.text-dark {
	color: #5b5b5b !important
}

.page-container {
	padding: 20px;
	position: relative;
	display: block
}

.page-header {
	background: #fff;
	border-radius: 15px;
	box-shadow: 0 1px 3px 0 rgba(54, 74, 99, .05);
	position: fixed;
	width: calc(100% - 40px);
	z-index: 999;
	border: 1px solid #e5e9f2
}

.page-header::before {
	content: '';
	display: block;
	width: 100%;
	height: 105px;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(44%, hsla(0, 0%, 97.3%, .94)), color-stop(73%, hsla(0, 0%, 97.3%, .49)), to(hsla(0, 0%, 100%, 0)));
	position: fixed;
	top: 0;
	left: 0
}

.page-header .navbar {
	background: #fff;
	border-radius: 15px;
	padding: 15px 25px;
	display: flex !important
}

.page-header .navbar .logo {
	display: flex !important;
	flex: 2
}

.page-header .navbar #navbarNav #leftNav>li>a {
	color: #5b5b5b;
	font-size: 14px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	border-radius: 10px;
	padding: 9px 10px;
	line-height: 22px
}
.page-header .navbar #navbarNav #leftNav>li>a img{
	height: 50px;
}

.page-header .navbar #navbarNav #leftNav>li>a:hover {
	background: #f3f6fa;
	color: #7889ff
}

.page-header .navbar #navbarNav #leftNav>li>a i {
	line-height: 22px;
	vertical-align: middle;
	font-size: 22px
}

.page-header .navbar #navbarNav #leftNav>li>a#sidebar-toggle {
	padding: 9px 0;
	margin-right: 10px;
	transform: rotate(0);
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

body.sidebar-hidden .page-header .navbar #navbarNav #leftNav>li>a#sidebar-toggle {
	transform: rotate(-180deg)
}
body.sidebar-hidden .page-header .navbar .menuClose {
	transform: rotate(-180deg)
}
.page-header .navbar #navbarNav #leftNav>li>a#sidebar-toggle:hover {
	background: 0 0
}

.page-header .navbar #headerNav,
.page-header .navbar #navbarNav {
	display: flex !important;
	flex: 10
}

.page-header .navbar #headerNav .navbar-nav,
.page-header .navbar #navbarNav .navbar-nav {
	flex-direction: row !important;
	align-items: center;
}

.page-header #sidebar-toggle svg {
	width: 22px;
	height: 22px
}

.page-header .navbar #headerNav>ul>li>a {
	padding: 9px 0;
	margin: 0 10px;
	color: #5b5b5b;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.page-header .navbar #headerNav>ul>li>a svg {
	line-height: 22px;
	vertical-align: middle;
	width: 21px;
	height: 21px
}

.page-header .navbar #headerNav>ul>li>a:hover {
	color: #7889ff
}

.page-header .navbar #headerNav>ul>li>a.notifications-dropdown {
	padding: 9px 15px;
	background: #fad7dd;
	color: #ee6e83;
	line-height: 22px;
	border-radius: 40px;
	min-width: 40px;
	text-align: center;
	font-weight: 700
}

.page-header .navbar #headerNav>ul>li>a.profile-dropdown {
	padding: 0;
	margin-right: 0
}

.page-header .navbar #headerNav>ul>li>a img {
	width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
    object-position: top;
}

.page-header .navbar .navbar-nav {
	flex: 10;
	padding: 0
}

.page-header .navbar .navbar-brand {
	margin-right: 0;
	/* background: url(../images/logo@2x.png) center center no-repeat; */
	background-size: cover;
	width: 40px;
	height: 40px;
	margin: 0 auto
}

.page-header #headerNav {
	flex: 10;
	align-items: flex-end;
	flex-direction: column
}

.page-header .header-notif {
	padding: 13px 26px;
	-webkit-transition: all .1s ease-in-out;
	-moz-transition: all .1s ease-in-out;
	-o-transition: all .1s ease-in-out;
	transition: all .1s ease-in-out
}

.page-header .header-notif:hover {
	background-color: #e4eff8
}

.page-header .header-notif .notif-image {
	float: left;
	margin-right: 10px;
	margin-top: 3px;
	border-radius: 50%
}

.page-header .header-notif .notif-image span {
	width: 40px;
	height: 40px;
	display: block;
	line-height: 40px;
	text-align: center;
	border-radius: 100%;
	display: inline-block
}

.page-header .header-notif .notif-image img {
	width: 40px;
	height: 40px;
	border-radius: 50%
}

.page-header .header-notif .notif-text {
	width: 223px;
	display: block;
	padding: 0 5px;
	display: inline-block;
	color: #5b5b5b;
	font-weight: 400
}

.page-header .header-notif .notif-text .bold-notif-text {
	font-weight: 500
}

.page-header .header-notif .notif-text p {
	margin: 0
}

.page-header .header-notif .notif-text small {
	opacity: .7
}

.profile-drop-menu a span {
	margin-left: 25px
}

.search-drop-menu {
	width: 340px
}

.notif-drop-menu {
	width: 340px;
	max-height: 420px;
	overflow-y: auto
}

.notif-drop-menu .dropdown-header {
	margin-bottom: 5px
}

.profile-drop-menu a svg {
	margin-right: 10px;
	width: 19px;
	height: 19px
}

.navbar-nav .dropdown-menu {
	position: absolute !important;
	visibility: hidden;
	opacity: 0;
	margin-top: 30px;
	display: block;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	right: 0;
}

.navbar-nav .dropdown-menu.show {
	margin-top: 10px;
	opacity: 1;
	visibility: visible
}

.slimScrollBar {
	background: rgba(0, 0, 0, .2) !important;
	border-radius: 4px !important
}

.page-sidebar {
	position: fixed;
	height: calc(100% - 148px);
	background: #fff;
	box-shadow: 0 1px 3px 0 rgba(54, 74, 99, .05);
	top: 148px;
	border-radius: 15px;
	width: 260px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	transform: scale(1, 1);
	transform-origin: left;
	overflow: visible
}

.page-sidebar .ps__rail-y {
	margin-top: 8px !important;
	margin-bottom: 8px !important;
	background-color: transparent !important
}

.page-sidebar .accordion-menu {
	height: 88% !important;
	padding: 20px 15px;
    overflow-y: scroll;
}
.copyright {
    padding: 20px 25px;
}
.copyright p{
	padding-right: 20px;
	opacity: .5;
}
.page-sidebar .accordion-menu>li>a {
	display: flex;
  align-items: center;
	color: #5b5b5b;
	-webkit-transition: all .1s ease-in-out;
	-moz-transition: all .1s ease-in-out;
	-o-transition: all .1s ease-in-out;
	transition: all .1s ease-in-out;
	line-height: 45px;
	padding: 0 15px;
	position: relative;
	text-decoration: none;
	-webkit-line-clamp: 1;
	line-clamp: 1;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}
.page-sidebar .accordion-menu>li>a .arrow-right{
    margin-left: 50px;
    margin-right: 0;
    width: 18px;
	position: absolute;
	right: 10px;
	position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
}
.page-sidebar .accordion-menu>li.active-page>a {
	border-radius: 10px;
	background-color: #e4eff8;
	color: #368bca;
	font-weight: 500;
	-webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
}

.page-sidebar .accordion-menu>li.active-page>a>svg {
	color: #368bca !important
}
.page-sidebar .accordion-menu li.open ul a svg{
	width: 10px;
    margin: 0 5px;
    margin-right: 15px;
}
.page-sidebar .accordion-menu>li.active-page ul li a.active {
	font-weight: 500;
	color: #368bca
}

.page-sidebar .accordion-menu>li>a:hover svg {
	margin-left: 5px
}

.page-sidebar .accordion-menu>li>a>svg {
	width: 21px;
	height: 21px;
	line-height: 40px;
	text-align: center;
	vertical-align: text-top;
	color: #5b5b5b;
	margin-right: 15px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.page-sidebar .accordion-menu li.sidebar-title {
	font-weight: 500;
	padding: 10px 15px;
	font-size: .875rem;
	color: #6c757d;
	opacity: .8
}

.page-sidebar .accordion-menu li a .dropdown-icon {
	float: right;
	vertical-align: middle;
	line-height: 44px;
	font-size: 10px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.page-sidebar .accordion-menu li.open>a>.dropdown-icon {
	visibility: visible;
	transform: rotate(90deg)
}

.page-sidebar .accordion-menu li ul {
	padding: 5px 0;
    list-style: none;
    background: #f4f6f9;
    margin-top: 5px;
    border-radius: 10px;
}

.page-sidebar .accordion-menu li ul li a {
	color: #5b5b5b;
	display: block;
	padding: 10px 15px;
	font-size: 14px;
	position: relative;
	-webkit-transition: all .15s ease-in-out;
	-moz-transition: all .15s ease-in-out;
	-o-transition: all .15s ease-in-out;
	transition: all .15s ease-in-out;
	text-decoration: none
}

.page-sidebar .accordion-menu li ul li a:hover {
	margin-left: 5px
}

.page-sidebar .accordion-menu li ul li a i {
	font-size: 10px;
	padding-right: 21px;
	padding-left: 6px
}

body.sidebar-hidden .page-sidebar {
	transform: scale(0, 1)
}

.page-content {
	margin-top: 100px;
	margin-left: 260px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.main-wrapper {
	padding: 28px 0 28px 28px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

body.sidebar-hidden .page-content {
	margin-left: 0
}

body.sidebar-hidden .main-wrapper {
	padding-left: 0
}

.popover {
	margin: 20px;
	border: 0;
	border-radius: 10px;
	box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08);
	color: #5b5b5b;
	font-family: Poppins, sans-serif !important
}

.popover-header {
	padding: 15px 25px 0;
	background: 0 0;
	border-bottom-color: transparent;
	font-size: 14px;
	opacity: .7
}

.popover-body {
	padding: 15px 25px;
	font-size: 13px;
	color: #7d7d83
}

.popover .arrow:before {
	display: none
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before,
.bs-popover-end>.popover-arrow::before,
.bs-popover-start>.popover-arrow::before,
.bs-popover-top>.popover-arrow::before {
	display: none
}

.row>* {
	padding-right: 14px;
	padding-left: 14px
}

.card {
	margin-bottom: 28px;
	border-radius: 15px;
	border: none;
	box-shadow: 0 1px 3px 0 rgba(54, 74, 99, .05)
}

.card-title {
	font-size: 18px;
	color: #5b5b5b;
	margin-bottom: 30px
}

.card-body {
	padding: 30px
}

.card-description {
	margin-bottom: 20px
}

.card-img,
.card-img-top {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px
}

.card-img,
.card-img-bottom {
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px
}

.card-footer,
.card-header {
	border-color: #e4e6ef
}

.list-group-item:first-child {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px
}

.list-group-item:last-child {
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px
}

.list-group-item {
	padding: 15px;
	border-color: #e4e6ef
}

.list-group-item.active {
	background-color: #368bca;
	border-color: #368bca
}

.list-group-item-primary {
	color: #368bca;
	background-color: #f0f2ff;
	font-weight: 500
}

.list-group-item-secondary {
	color: #7e8299;
	background-color: #e4eff8;
	font-weight: 500
}

.list-group-item-success {
	color: #6bcac2;
	background-color: #d3fbf9;
	font-weight: 500
}

.list-group-item-danger {
	color: #ee6e83;
	background-color: #fad7dd;
	font-weight: 500
}

.list-group-item-warning {
	color: #ffaf0f;
	background-color: #fff5e0;
	font-weight: 500
}

.list-group-item-info {
	color: #9c6efc;
	background-color: #efe8ff;
	font-weight: 500
}

.list-group-item-light {
	color: #7e8299;
	background-color: #e4eff8;
	font-weight: 500
}

.list-group-item-dark {
	color: #5b5b5b;
	background-color: #d1d3e0;
	font-weight: 500
}

.card-header:first-child {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px
}

.card-footer,
.card-header {
	padding: 15px;
	background-color: #fff
}

.card-footer:last-child {
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px
}

.card-img-overlay {
	border-radius: 15px
}

.card.bg-primary .card-header {
	background-color: #368bca;
	border-color: #368bca
}

.card.bg-primary .card-title {
	color: #fff
}

.card.bg-secondary .card-header {
	background-color: #e4e6ef;
	color: #5b5b5b
}

.card.bg-secondary .card-body {
	color: #5b5b5b
}

.card.bg-success .card-header {
	background-color: #6bcac2;
	border-color: #6bcac2
}

.card.bg-success .card-title {
	color: #fff
}

.card.bg-danger .card-header {
	background-color: #f75d6fd8;
	border-color: #f75d6fd8
}

.card.bg-danger .card-title {
	color: #fff
}

.card.bg-warning .card-header {
	background-color: #f5af47ea;
	border-color: #f5af47ea
}

.card.bg-warning .card-title {
	color: #fff
}

.card.bg-info .card-header {
	background-color: #9465fcea;
	border-color: #9465fcea
}

.card.bg-info .card-title {
	color: #fff
}

.card.bg-dark .card-header {
	background-color: #242e44;
	border-color: #242e44
}

.card.bg-dark .card-title {
	color: #fff
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	background-color: #368bca
}

.nav-pills .nav-link {
	border-radius: 10px
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link,
.nav-tabs .nav-link.active {
	border: 0
}

.toast {
	border-radius: 15px;
	border: none;
	box-shadow: 0 0 1.25rem rgb(31 45 61 / 8%)
}

.toast-header {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	padding: 10px 15px
}

.toast-body {
	padding: 15px
}

.toast-header strong {
	font-weight: 500
}

.alert {
	border-radius: 15px;
	padding: 12px 15px;
	font-weight: 500
}

.alert-primary {
	background-color: #f0f2ff;
	border-color: #f0f2ff;
	color: #368bca
}

.alert-secondary {
	background-color: #e4eff8;
	border-color: #e4eff8;
	color: #7e8299
}

.alert-success {
	background-color: #d3fbf9;
	border-color: #d3fbf9;
	color: #6bcac2
}

.alert-danger {
	background-color: #fad7dd;
	border-color: #fad7dd;
	color: #ee6e83
}

.alert-warning {
	background-color: #fff5e0;
	border-color: #fff5e0;
	color: #ffaf0f
}

.alert-info {
	background-color: #efe8ff;
	border-color: #efe8ff;
	color: #9c6efc
}

.alert-light {
	background-color: #e4eff8;
	border-color: #e4eff8
}

.alert-dark {
	background-color: #d1d3e0;
	border-color: #d1d3e0;
	color: #5b5b5b
}

.alert-primary.outline-alert {
	background-color: #fff;
	border-color: #368bca;
	color: #368bca;
	font-weight: 400
}

.alert-secondary.outline-alert {
	background-color: #fff;
	border-color: #e4e6ef;
	font-weight: 400
}

.alert-success.outline-alert {
	background-color: #fff;
	border-color: #6bcac2;
	color: #6bcac2;
	font-weight: 400
}

.alert-danger.outline-alert {
	background-color: #fff;
	border-color: #f75d6fd8;
	color: #f75d6fd8;
	font-weight: 400
}

.alert-warning.outline-alert {
	background-color: #fff;
	border-color: #f5af47ea;
	color: #f5af47ea;
	font-weight: 400
}

.alert-info.outline-alert {
	background-color: #fff;
	border-color: #9465fcea;
	color: #9465fcea;
	font-weight: 400
}

.alert-light.outline-alert {
	background-color: #fff;
	border-color: #e4eff8;
	font-weight: 400
}

.alert-dark.outline-alert {
	background-color: #fff;
	border-color: #242e44;
	color: #383d41;
	font-weight: 400
}

.icon-list-row {
	margin-top: 30px;
	text-align: center
}

.icon-list-row i {
	font-size: 23px
}

.icon-state-info {
	color: #368bca
}

.badge {
	font-weight: 500;
	padding: 7px 12px
}

.badge:not(.badge-pill) {
	border-radius: 7px
}

.badge.bg-primary {
	background-color: #f0f2ff !important;
	color: #368bca
}

.badge.bg-secondary {
	background-color: #e4eff8 !important;
	color: #7e8299 !important
}

.badge.bg-success {
	background-color: #d3fbf9 !important;
	color: #6bcac2
}

.badge.bg-danger {
	background-color: #fad7dd !important;
	color: #ee6e83
}

.badge.bg-warning {
	background-color: #fff5e0 !important;
	color: #ffaf0f
}

.badge.bg-info {
	background-color: #efe8ff !important;
	color: #9c6efc
}

.badge.bg-dark {
	background-color: #d1d3e0 !important;
	color: #5b5b5b
}

.btn {
	padding: 8px 20px;
	border-radius: 10px;
	font-weight: 500
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
	background-color: #e4eff8;
	border-color: #e4eff8;
	color: #368bca
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
	background-color: #f0f2ff;
	border-color: #f0f2ff;
	color: #368bca;
	box-shadow: 0 7px 23px -8px #f0f2ff
}

.btn-secondary,
.btn-secondary.disabled,
.btn-secondary:disabled {
	background-color: #e4eff8;
	border-color: #e4eff8;
	color: #7e8299
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
	background-color: #e4eff8;
	border-color: #e4eff8;
	color: #7e8299;
	box-shadow: 0 7px 23px -8px #e4eff8
}

.btn-success,
.btn-success:disabled,
.btn-sucess.disabled {
	background-color: #d3fbf9;
	border-color: #d3fbf9;
	color: #6bcac2
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active {
	background-color: #d3fbf9;
	border-color: #d3fbf9;
	color: #6bcac2;
	box-shadow: 0 7px 23px -8px #d3fbf9
}

.btn-danger,
.btn-danger.disabled,
.btn-danger:disabled {
	background-color: #fad7dd;
	border-color: #fad7dd;
	color: #ee6e83
}

.btn-danger.focus,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active {
	background-color: #fad7dd;
	border-color: #fad7dd;
	color: #ee6e83;
	box-shadow: 0 7px 23px -8px #fad7dd
}

.btn-warning,
.btn-warning.disabled,
.btn-warning:disabled {
	background-color: #fff5e0;
	border-color: #fff5e0;
	color: #ffaf0f
}

.btn-warning.focus,
.btn-warning:focus,
.btn-warning:hover,
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active {
	background-color: #fff5e0;
	border-color: #fff5e0;
	color: #ffaf0f;
	box-shadow: 0 7px 23px -8px #fff5e0
}

.btn-info,
.btn-info.disabled,
.btn-info:disabled {
	background-color: #efe8ff;
	border-color: #efe8ff;
	color: #9c6efc
}

.btn-info.focus,
.btn-info:focus,
.btn-info:hover,
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active {
	background-color: #efe8ff;
	border-color: #efe8ff;
	color: #9c6efc;
	box-shadow: 0 7px 23px -8px #efe8ff
}

.btn-light,
.btn-light.disabled,
.btn-light:disabled {
	background-color: #e4eff8;
	border-color: #e4eff8;
	color: #7e8299
}

.btn-light:focus,
.btn-light:hover .btn-light.focus,
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active {
	background-color: #e4eff8;
	border-color: #e4eff8;
	color: #7e8299;
	box-shadow: 0 7px 23px -8px #e4eff8
}

.btn-dark,
.btn-dark.disabled,
.btn-dark:disabled {
	background-color: #d1d3e0;
	border-color: #d1d3e0;
	color: #5b5b5b
}

.btn-dark.focus,
.btn-dark:focus,
.btn-dark:hover,
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active {
	background-color: #d1d3e0;
	border-color: #d1d3e0;
	color: #5b5b5b;
	box-shadow: 0 7px 23px -8px #d1d3e0
}

.btn-link {
	color: #368bca;
	text-decoration: none
}

.btn-link:hover {
	color: #368bca;
	text-decoration: underline
}

.btn-outline-primary {
	color: #368bca;
	border-color: #368bca
}

.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
	color: #fff;
	border-color: #368bca;
	background-color: #368bca;
	box-shadow: 0 7px 23px -8px #368bca
}

.btn-outline-secondary {
	color: #5b5b5b;
	border-color: #e4e6ef
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus,
.btn-outline-secondary:hover,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
	color: #5b5b5b;
	border-color: #e4e6ef;
	background-color: #e4e6ef;
	box-shadow: 0 7px 23px -8px #e4e6ef
}

.btn-outline-success {
	color: #6bcac2;
	border-color: #6bcac2
}

.btn-outline-success.focus,
.btn-outline-success:focus,
.btn-outline-success:hover,
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active {
	color: #fff;
	border-color: #6bcac2;
	background-color: #6bcac2;
	box-shadow: 0 7px 23px -8px #6bcac2
}

.btn-outline-danger {
	color: #f75d6fd8;
	border-color: #f75d6fd8
}

.btn-outline-danger.focus,
.btn-outline-danger:focus,
.btn-outline-danger:hover,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active {
	color: #fff;
	border-color: #f75d6fd8;
	background-color: #f75d6fd8;
	box-shadow: 0 7px 23px -8px #f75d6fd8
}

.btn-outline-warning {
	color: #f5af47ea;
	border-color: #f5af47ea
}

.btn-outline-warning.focus,
.btn-outline-warning:focus,
.btn-outline-warning:hover,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active {
	color: #fff;
	border-color: #f5af47ea;
	background-color: #f5af47ea;
	box-shadow: 0 7px 23px -8px #f5af47ea
}

.btn-outline-info {
	color: #9465fcea;
	border-color: #9465fcea
}

.btn-outline-info.focus,
.btn-outline-info:focus,
.btn-outline-info:hover,
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active {
	color: #fff;
	border-color: #9465fcea;
	background-color: #9465fcea;
	box-shadow: 0 7px 23px -8px #9465fcea
}

.btn-outline-dark {
	color: #5b5b5b;
	border-color: #d1d3e0
}

.btn-outline-dark.focus,
.btn-outline-dark:focus,
.btn-outline-dark:hover,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active {
	color: #5b5b5b;
	border-color: #d1d3e0;
	background-color: #d1d3e0;
	box-shadow: 0 7px 23px -8px #d1d3e0
}

body.modal-open {
	overflow: visible
}

.modal-content {
	border-radius: 15px;
	border: none
}

.modal-header {
	padding: 30px;
	border: 0
}

.modal-footer {
	padding: 30px;
	border: 0
}

.modal-body {
	padding: 0 30px
}

.dropdown-menu {
	border-radius: 15px;
	border: none;
	box-shadow: 0 0 1.25rem rgb(31 45 61 / 5%);
	padding: 15px 0;
	margin: 10px 0
}

.dropdown-item {
	color: #5b5b5b;
	padding: 9px 26px
}

.dropdown-item:hover {
	background: 0 0;
	color: #368bca
}

.dropdown-divider {
	border-color: #eaecf3
}

.dropdown-menu-dark {
	background-color: #d1d3e0
}

.dropdown-menu-dark .dropdown-item {
	color: #5b5b5b
}

.dropdown-menu-dark .dropdown-item:hover {
	background: 0 0;
	color: #368bca
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
	color: #368bca;
	background: 0 0
}

.dropdown-item.active,
.dropdown-item:active {
	color: #368bca;
	background: 0 0
}

.dropdown-header {
	padding: 8px 26px
}

.dropdown-menu form {
	padding: 8px 20px
}

.page-link {
	color: #368bca;
	border-color: #e4e6ef
}

.page-item.disabled .page-link {
	border-color: #e4e6ef
}

.page-item:first-child .page-link {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px
}

.page-item:last-child .page-link {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px
}

.page-item.active .page-link {
	background-color: #368bca;
	border-color: #368bca
}

.progress {
	background-color: #f5f6fa
}

.progress-bar {
	background-color: #368bca
}

.accordion-button {
	border: none;
	-webkit-box-shadow: 0 0 7px 0 rgba(63, 71, 105, .08);
	box-shadow: 0 0 7px 0 rgba(63, 71, 105, .08);
	padding: 20px;
	font-size: 16px;
}

.accordion-collapse {
	border: none
}
.accordion-item{
	border: none;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
.accordion-button:not(.collapsed) {
	background-color: #e4eff8;
	color: #368bca
}

.accordion-item:first-of-type .accordion-button {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px
}

.accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px
}

.accordion-flush .accordion-button {
	box-shadow: none
}

.form-control {
	border-color: #e4e6ef;
	border-radius: 10px;
	padding: .65rem 1rem;
	font-size: 14px;
}

.form-control:disabled,
.form-control[readonly],
.form-select:disabled {
	background-color: #f5f6fa;
	color: #212529
}

.form-control-color {
	padding: 7px
}

.form-select {
	border-color: #e4e6ef;
	border-radius: 10px;
	padding: .65rem 1rem
}

.form-check-input:checked {
	background-color: #9465fcea;
	border-color: #9465fcea
}

.input-group-text {
	border-radius: 10px;
	background-color: #f7f7f7;
	border-color: #e4e6ef
}

.form-control.is-valid,
.form-select.is-valid,
.was-validated .form-control:valid,
.was-validated .form-select:valid {
	border-color: #6bcac2
}

.form-check-input.is-valid~.form-check-label,
.valid-feedback,
.was-validated .form-check-input:valid~.form-check-label {
	color: #6bcac2
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
	background-color: #6bcac2;
	border-color: #6bcac2
}

.form-control.is-invalid,
.form-select.is-invalid,
.was-validated .form-control:invalid,
.was-validated .form-select:invalid {
	border-color: #f75d6fd8
}

.invalid-feedback {
	color: #f75d6fd8
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
	color: #f75d6fd8
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
	border-color: #f75d6fd8
}

.table {
	color: #5b5b5b;
	border-color: #e4e6ef
}

.table img {
	margin-right: 10px
}

.table-primary {
	--bs-table-bg: #f0f2ff;
	--bs-table-striped-bg: #f0f2ff;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #f0f2ff;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #f0f2ff;
	--bs-table-hover-color: #000;
	color: #368bca;
	border-color: #f0f2ff
}

.table-secondary {
	--bs-table-bg: #e4eff8;
	--bs-table-striped-bg: #e4eff8;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #e4eff8;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #e4eff8;
	--bs-table-hover-color: #000;
	color: #7e8299;
	border-color: #e4eff8
}

.table-success {
	--bs-table-bg: #d3fbf9;
	--bs-table-striped-bg: #d3fbf9;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #d3fbf9;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #d3fbf9;
	--bs-table-hover-color: #000;
	color: #6bcac2;
	border-color: #d3fbf9
}

.table-danger {
	--bs-table-bg: #fad7dd;
	--bs-table-striped-bg: #fad7dd;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #fad7dd;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #fad7dd;
	--bs-table-hover-color: #000;
	color: #ee6e83;
	border-color: #fad7dd
}

.table-warning {
	--bs-table-bg: #fff5e0;
	--bs-table-striped-bg: #fff5e0;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #fff5e0;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #fff5e0;
	--bs-table-hover-color: #000;
	color: #ffaf0f;
	border-color: #fff5e0
}

.table-info {
	--bs-table-bg: #efe8ff;
	--bs-table-striped-bg: #efe8ff;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #efe8ff;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #efe8ff;
	--bs-table-hover-color: #000;
	color: #9c6efc;
	border-color: #efe8ff
}

.table-light {
	--bs-table-bg: #e4eff8;
	--bs-table-striped-bg: #e4eff8;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #e4eff8;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #e4eff8;
	--bs-table-hover-color: #000;
	color: #5b5b5b;
	border-color: #e4eff8
}

.table-dark {
	--bs-table-bg: #D1D3E0;
	--bs-table-striped-bg: #D1D3E0;
	--bs-table-striped-color: #fff;
	--bs-table-active-bg: #D1D3E0;
	--bs-table-active-color: #fff;
	--bs-table-hover-bg: #D1D3E0;
	--bs-table-hover-color: #fff;
	color: #5b5b5b;
	border-color: #d1d3e0
}

.table-dark th {
	background-color: #d1d3e0
}

.table-light th {
	background-color: #e4eff8
}

.table td, th, .table{
	border-color: #eaecf3 !important
}


.table th {
	padding: 15px 20px !important;
	font-weight: 400;
	vertical-align: middle
}
.table td{
	padding: 15px 20px !important;
	font-weight: 400;
	color: #333;
	vertical-align: middle
}

.table tbody tr:last-child td,
.table tbody tr:last-child th {
	border-bottom: none
}

.table tfoot tr td,
.table tfoot tr th {
	border-bottom: none;
	border-top-width: 1px
}

.table-hover>tbody>tr {
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.table-hover>tbody>tr:hover {
	--bs-table-hover-bg: transparent;
	background: #f5f6fa
}

.table-striped>tbody>tr:nth-of-type(odd) {
	--bs-table-striped-bg: #F5F6FA
}

.table-striped>tbody>tr:nth-of-type(odd) {
	color: #5b5b5b
}

.blockUI.blockOverlay {
	border-radius: inherit;
	background: rgba(255, 255, 255) !important;
	opacity: .8 !important
}

.blockUI.blockMsg {
	background: 0 0 !important;
	width: 25px !important;
	border: 0 !important;
	height: 25px !important;
	left: calc(50% - 12.5px) !important;
	top: calc(50% - 12.5px) !important
}

table.dataTable {
	margin-top: 20px !important;
	margin-bottom: 20px !important
}

table.dataTable td,
table.dataTable th {
	padding: 15px 25px !important;
	border-bottom: 1px solid #e8e8e8 !important
}

table.dataTable tfoot th {
	border-bottom: 0 !important
}

table.dataTable tfoot th,
table.dataTable thead th {
	font-weight: 700;
	text-transform: uppercase;
	font-family: Montserrat, sans-serif;
	font-size: 12px;
	color: rgba(92, 102, 98, .8)
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:before {
	content: '\f077';
	font-family: "Font Awesome 5 Free";
	font-size: 14px;
	right: 10px;
	bottom: calc(50% - 4px)
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc_disabled:after {
	content: '\f078';
	font-family: "Font Awesome 5 Free";
	font-size: 14px;
	right: 10px;
	bottom: calc(50% - 14px)
}

div.dataTables_wrapper div.dataTables_length select {
	margin: 0 10px
}

div.dataTables_wrapper div.dataTables_filter input {
	margin-left: 10px
}

tr.group {
	background-color: #fafafa !important
}

.error-page .container,
.error-page .error-container {
	height: 100%;
	position: relative
}

.error-info {
	position: absolute;
	top: 50%;
	transform: translateY(-50%)
}

.error-info h1 {
	font-size: 148px;
	color: #368bca
}

.error-info p {
	font-size: 14px
}

.error-image {
	position: absolute;
	right: 0;
	height: 100%;
	width: 50%;
	/* background: url(../images/500.svg) center center no-repeat; */
	background-size: contain
}

body.err-500 .error-image {
	/* background: url(../images/500.svg) center center no-repeat; */
	background-size: contain
}

body.error-page .container {
	max-width: 1140px
}

.invoice-details {
	margin-top: 20px;
	margin-bottom: 40px
}

.invoice-details p {
	margin-bottom: 3px
}

.invoice-details p.info {
	font-weight: 500;
	text-transform: uppercase;
	color: #3a3a3a;
	margin-bottom: 10px
}

.invoice-info p {
	margin-bottom: 8px
}

.invoice-info p span {
	float: right
}

.invoice-table th {
	font-weight: 500
}

.invoice-table img {
	width: 35px;
	height: 35px
}

.invoice-table a {
	color: #5b5b5b;
	margin-right: 5px
}

.invoice-table svg {
	width: 18px;
	height: 18px
}

.invoice-last {
	margin-top: 40px
}

.login-page .container,
.login-page .container .row {
	height: 100vh
}

.login-page .container .row .login-box-container {
	top: 50%;
	transform: translateY(-50%)
}

.authent-logo {
	text-align: center
}

.authent-text {
	text-align: center;
	margin: 20px 0;
	font-size: 16px
}

.authent-text p {
	margin-bottom: 5px
}

.authent-reg {
	text-align: center;
	margin-top: 20px
}

.authent-reg p {
	margin-bottom: 0
}

.authent-login {
	margin-top: 10px;
	text-align: center
}

.authent-login p {
	margin-bottom: 0
}

.io-pricing-table {
	text-align: center
}

.io-pricing-table li {
	padding: 20px
}

.email-page .main-wrapper {
	padding-bottom: 0
}

.email-page .main-wrapper>.row>div>.card {
	margin-bottom: 0
}

.email-list {
	max-height: calc(100vh - 207px);
	overflow-y: auto
}

.email-list ul {
	margin-bottom: 0
}

.email-list-item {
	color: #5b5b5b
}

.email-list-item .email-author img {
	width: 35px;
	border-radius: 100%
}

.email-list-item .email-author span.author-name {
	margin-left: 7px;
	line-height: 25px
}

.email-list-item .email-author span.email-date {
	float: right;
	opacity: .6;
	line-height: 25px;
	font-size: 12px
}

.email-list-item .email-info {
	display: block;
	margin-top: 8px
}

.email-list-item .email-info .email-subject {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: block
}

.email-list-item .email-info .email-text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: block;
	opacity: .6
}

.email-list li a:active,
.email-list li a:focus,
.email-menu li a:active,
.email-menu li a:focus {
	color: #5b5b5b
}

.email-list li a {
	border-radius: 15px;
	display: block;
	padding: 18px 12px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.email-list li:last-child a {
	border-color: transparent
}

.email-list li a:hover {
	background: #e4eff8
}

.email-list li.active a {
	margin-bottom: 5px;
	background: #e4eff8
}

.open-email-content {
	max-height: calc(100vh - 207px);
	overflow-y: auto
}

.mail-header {
	width: 100%;
	display: block;
	overflow: hidden
}

.mail-title {
	float: left;
	line-height: 20px;
	padding: 4px 0;
	max-width: calc(100% - 285px)
}

.mail-actions {
	float: right
}

.mail-info {
	display: block;
	width: 100%;
	overflow: hidden;
	padding-top: 20px;
	padding-bottom: 30px;
	border-bottom: 1px solid #eaecf3
}

.mail-info .mail-author {
	float: left
}

.mail-info .mail-author img {
	width: 50px;
	border-radius: 50px;
	border: 4px solid #f9f9f9;
	float: left
}

.mail-info .mail-author-info {
	padding: 0 10px;
	display: inline-block;
	height: 50px
}

.mail-info .mail-author span.mail-author-name {
	display: block;
	line-height: 25px
}

.mail-info .mail-author span.mail-author-address {
	display: block;
	line-height: 25px;
	opacity: .6
}

.mail-info .mail-other-info {
	float: right
}

.mail-info .mail-other-info span {
	line-height: 50px
}

.mail-text {
	margin-top: 30px
}

.mail-attachment {
	margin-top: 30px
}

.mail-attachment-files {
	margin-top: 30px
}

.mail-attachment-files .card {
	width: 200px;
	float: left;
	margin-right: 20px
}

.mail-attachment-files .card .card-body {
	padding: 15px;
	font-size: 14px
}

.mail-attachment-files .card .card-title {
	margin-bottom: 10px;
	font-size: 14px
}

.file-manager-menu ul li {
	padding: 10px
}

.file-manager-menu ul li {
	-webkit-transition: all .1s ease-in-out;
	-moz-transition: all .1s ease-in-out;
	-o-transition: all .1s ease-in-out;
	transition: all .1s ease-in-out
}

.file-manager-menu ul li a:hover {
	margin-left: 5px
}

.file-manager-menu ul li a {
	color: #5b5b5b;
	-webkit-transition: all .1s ease-in-out;
	-moz-transition: all .1s ease-in-out;
	-o-transition: all .1s ease-in-out;
	transition: all .1s ease-in-out
}

.file-manager-menu ul li a i {
	padding-right: 10px
}

.file-manager-menu.label-menu ul li a i {
	width: 6px;
	height: 10px;
	border-radius: 50%;
	background: #000;
	display: inline-block;
	vertical-align: middle;
	margin: 0 16px 0 6px
}

.fmm-title {
	text-transform: uppercase;
	padding: 10px;
	display: block;
	opacity: .6
}

.file-manager-menu .progress {
	margin-top: 10px;
	margin-left: 10px
}

.storage-info-text {
	padding: 10px
}

.card-file-manager .card-text {
	opacity: .6
}

.card-file-header {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	vertical-align: middle;
	font-size: 48px;
	text-align: center;
	line-height: 130px;
	background: #fbfbfb
}

.folder .folder-icon {
	float: left
}

.folder .folder-icon i {
	font-size: 43px
}

.folder .folder-info {
	display: block;
	margin-left: 20px;
	float: left
}

.folder .folder-info a {
	margin-bottom: 0;
	display: block;
	color: #5b5b5b
}

.folder .folder-info span {
	opacity: .6
}

.profile-cover {
	width: 100%;
	display: block;
	position: relative;
	border-radius: 15px;
	z-index: 1;
	height: 350px;
	/* background: url(../images/profile-bg.jpg) center center no-repeat; */
	background-size: cover
}

.profile-cover:after {
	display: block;
	content: ' ';
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 15px;
	background: linear-gradient(rgba(0, 0, 0, .3), #000)
}

.profile-header {
	display: block;
	width: 100%;
	margin-top: -110px;
	padding: 0 30px;
	overflow: hidden;
	z-index: 2;
	position: relative;
	margin-bottom: 20px
}

.profile-header .profile-img img {
	width: 150px;
	height: 150px;
	border-radius: 150px
}

.profile-header .profile-img {
	float: left
}

.profile-header .profile-name {
	float: left;
	margin-top: 50px;
	margin-left: 30px
}

.profile-header .profile-name h3 {
	color: #fff;
	font-weight: 400;
	font-size: 22px;
	line-height: 50px
}

.profile-header .profile-header-menu {
	float: right;
	height: 100%;
	margin-top: 39px
}

.profile-header .profile-header-menu ul li {
	float: left;
	height: 100%;
	padding: 25px
}

.profile-header .profile-header-menu ul li a {
	color: #7d7d83;
	display: block;
	font-weight: 400;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.profile-header .profile-header-menu ul li a.active,
.profile-header .profile-header-menu ul li a:hover {
	color: #fff
}

ul.profile-about-list {
	margin-bottom: 0
}

ul.profile-about-list li {
	margin-bottom: 10px
}

ul.profile-about-list li.profile-about-list-buttons {
	margin-bottom: 0
}

.story-list .story {
	overflow: hidden;
	display: block;
	margin-bottom: 10px
}

.story-list .story:last-child {
	margin-bottom: 0
}

.story-list .story img {
	width: 50px;
	height: 50px;
	border-radius: 50px;
	border: 2px solid #368bca;
	padding: 2px;
	float: left
}

.story-list .story .story-info {
	float: left;
	padding: 5px 15px
}

.story-list .story .story-info span.story-author {
	display: block;
	line-height: 20px;
	color: #5b5b5b
}

.story-list .story .story-info span.story-time {
	line-height: 20px;
	font-size: 13px;
	opacity: .6
}

.post-body,
.post-header {
	display: block;
	overflow: hidden;
	width: 100%
}

.post-header img {
	width: 50px;
	height: 50px;
	border-radius: 50px;
	float: left
}

.post-header .post-info {
	float: left;
	padding: 4px 15px 0 15px
}

.post-header .post-info span.post-date {
	font-size: 13px;
	opacity: .6
}

.post-header .post-header-actions {
	float: right;
	padding: 15px 0
}

.post-header .post-header-actions a {
	line-height: 20px;
	color: #646464
}

.post-header .post-header-actions a:hover {
	color: #333
}

.post-body {
	padding: 15px 0 0 0;
	position: relative
}

.post-body p {
	margin-bottom: 0
}

.post-header .post-info span.post-author {
	font-size: 15px
}

.post-body .post-image {
	display: block;
	width: 100%;
	border-radius: 6px;
	margin-top: 15px
}

.post-actions,
.post-actions ul {
	display: block;
	overflow: hidden;
	margin-bottom: 0
}

.post-actions {
	margin-top: 15px;
	padding: 5px 10px 0
}

.post-actions ul li a {
	float: left;
	padding: 5px 8px;
	font-size: 15px;
	color: #5b5b5b;
	margin-right: 10px
}

.post-actions ul li a:hover {
	color: #000
}

.post-actions ul li a.liked {
	color: #844ff1
}

.post-actions ul li a.liked i:before {
	content: '\f004'
}

.post-actions ul li a i {
	margin-right: 7px
}

.post-comments {
	margin-top: 5px;
	padding-top: 15px
}

.post-comments .post-comm {
	display: block;
	margin-bottom: 10px;
	background: #f3f6fa;
	border-radius: 15px;
	padding: 15px;
	overflow: hidden
}

.post-comments .post-comm:last-child {
	margin-bottom: 0
}

.post-comments .post-comm img {
	width: 30px;
	height: 30px;
	border-radius: 30px;
	float: left
}

.post-comments .post-comm .comment-container {
	float: left;
	padding: 0 10px;
	width: calc(100% - 30px);
	display: block
}

.post-comments .post-comm .comment-container span.comment-author {
	color: #5b5b5b;
	display: block;
	line-height: 30px
}

.post-comments .post-comm .comment-container span.comment-author small {
	float: right;
	font-size: 13px;
	color: #5b5b5b;
	opacity: .6
}

.post-comments .post-comm .comment-text {
	padding: 10px 0 0 0;
	display: inline-block
}

.fc-toolbar h2 {
	font-size: 18px !important;
	font-weight: 400;
	color: #5b5b5b
}

.fc-next-button.fc-button.fc-button-primary,
.fc-next-button.fc-button.fc-button-primary.active,
.fc-next-button.fc-button.fc-button-primary:focus,
.fc-prev-button.fc-button.fc-button-primary,
.fc-prev-button.fc-button.fc-button-primary.active,
.fc-prev-button.fc-button.fc-button-primary:focus {
	background-color: #f0f2ff;
	border-color: #f0f2ff;
	color: #368bca;
	border-radius: 10px;
	padding: 8px 5px
}

.fc-next-button.fc-button.fc-button-primary:hover,
.fc-prev-button.fc-button.fc-button-primary:hover {
	background-color: #f0f2ff;
	border-color: #f0f2ff;
	color: #368bca;
	box-shadow: 0 7px 23px -8px #f0f2ff
}

.fc-today-button.fc-button.fc-button-primary,
.fc-today-button.fc-button.fc-button-primary.active,
.fc-today-button.fc-button.fc-button-primary:focus {
	background-color: #e4eff8;
	border-color: #e4eff8;
	color: #7e8299;
	border-radius: 10px;
	padding: 8px 20px
}

.fc .fc-button-primary:disabled {
	background-color: #e4eff8;
	border-color: #e4eff8;
	color: #7e8299
}

#calendar a {
	color: #5b5b5b
}

.fc-col-header-cell-cushion {
	font-weight: 400
}

.fc .fc-daygrid-day.fc-day-today {
	background-color: #f3f6fa
}

.fc-scrollgrid-section.fc-scrollgrid-section-header {
	border: none
}

.fc-h-event {
	background-color: #f0f2ff;
	border-color: #f0f2ff;
	padding: 3px;
	border-radius: 15px;
	cursor: pointer;
}

.fc-event-title.fc-sticky {
	padding: 2px 10px;
	color: #368bca;
	font-weight: 500
}

.fc-daygrid-event {
	border-radius: 15px
}

.fc-daygrid-event:hover {
	background-color: #f3f6fa
}

.fc-daygrid-dot-event .fc-event-title {
	font-weight: 400
}

.fc-daygrid-event-dot {
	border-color: #368bca
}

.stat-widget p {
	float: left;
	opacity: .6
}

.stat-widget h2 {
	font-size: 28px;
}

.stat-widget .progress {
	clear: both
}

.table-widget img {
	width: 35px
}

.table-widget .card-body {
	padding: 30px 0 0 0
}

.table-widget .card-title {
	padding: 0 30px 0 30px
}

.table-widget .table td,
.table-widget .table th {
	padding: 15px 30px !important
}

.table-widget .table {
	margin-bottom: 0
}

.card-meet-header {
	margin-bottom: 25px
}

.card-meet-header .card-meet-day {
	float: left;
	padding-right: 18px;
	margin-right: 18px;
	text-align: center;
	border-right: 1px solid #d8d6de
}

.card-meet-text h6 {
	font-size: 16px
}

.transactions-list {
	margin-bottom: 25px
}

.tr-item {
	display: flex;
	justify-content: space-between
}

.tr-item .tr-text h4 {
	font-size: 14px;
	margin-bottom: 2px
}

.tr-item .tr-text p {
	font-size: 12px;
	margin-bottom: 0
}

.tr-company-name {
	display: flex
}

.tr-rate {
	align-self: center
}

.tr-rate p {
	margin-bottom: 0;
	font-weight: 500
}

.tr-icon {
	margin-right: 16px
}

.tr-card-icon {
	border-radius: 12px;
	width: 48px;
	height: 48px;
	padding: 11px 14px
}

.tr-card-icon svg {
	width: 20px;
	height: 20px
}

.tr-text {
	align-self: center
}

.tr-text a {
	color: #5b5b5b
}

.tr-card-bg-primary {
	background-color: #f0f2ff !important;
	color: #368bca
}

.tr-card-bg-secondary {
	background-color: #e4eff8;
	color: #7e8299
}

.tr-card-bg-success {
	background-color: #d3fbf9
}

.tr-card-bg-danger {
	background-color: #fad7dd !important;
	color: #ee6e83
}

.tr-card-bg-warning {
	background-color: #fff5e0 !important;
	color: #ffaf0f
}

.tr-card-bg-info {
	background-color: #efe8ff !important;
	color: #9c6efc
}

.loader {
	position: fixed;
	height: 100%;
	width: 100%;
	background: #fff;
	z-index: 9999;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}

.loader .spinner-grow {
	top: 50%;
	left: 50%;
	margin: -20px 0 0 -20px;
	position: absolute;
	width: 40px;
	height: 40px
}

.no-loader .loader {
	visibility: hidden;
	opacity: 0
}

@media (max-width:1350px) {
	.page-header .navbar #navbarNav {
		flex: 0;
		margin-right: 15px
	}

	.page-header .navbar .logo {
		flex: 0 !important
	}

	.page-header .navbar #navbarNav .navbar-nav li a:not(#sidebar-toggle) {
		display: none
	}

	.page-header .navbar #navbarNav #leftNav>li>a#sidebar-toggle {
		transform: rotate(-180deg)
	}

	body.sidebar-hidden .page-header .navbar #navbarNav #leftNav>li>a#sidebar-toggle {
		transform: rotate(0)
	}

	.page-sidebar {
		transform: scale(0, 1);
		z-index: 999
	}

	body.sidebar-hidden .page-sidebar {
		transform: scale(1, 1)
	}

	.page-content {
		margin-left: 0
	}

	.page-content .main-wrapper {
		padding-left: 0
	}

	body.sidebar-hidden .page-content {
		transform: translateX(260px)
	}

	body.sidebar-hidden .page-content {
		padding-left: 20px
	}
}

@media (max-width:1200px) {
	.profile-header {
		margin-top: -60px
	}

	.profile-cover {
		height: 200px
	}

	.profile-img {
		display: block;
		float: none !important
	}

	.profile-img img {
		margin: 0 auto;
		display: inherit;
		width: 100px !important;
		height: 100px !important;
		border-radius: 100px !important
	}

	.profile-name {
		display: block;
		float: none !important;
		text-align: center;
		margin: 0 !important
	}

	.profile-name h3 {
		color: #333 !important
	}

	.profile-header .profile-header-menu {
		display: block;
		float: none;
		margin: 0;
		text-align: center
	}

	.profile-header .profile-header-menu ul li {
		display: block;
		padding: 0;
		width: calc(100%/6);
		text-align: center
	}

	.profile-header .profile-header-menu ul li a {
		padding: 10px;
		text-align: center;
		height: auto !important
	}

	.profile-header .profile-header-menu ul li a.active,
	.profile-header .profile-header-menu ul li a:hover {
		color: #000
	}

	.mail-actions {
		display: none
	}

	.mail-title {
		max-width: 100%
	}
}

@media (max-width:992px) {
	.error-image {
		display: none
	}

	.error-info {
		width: 100%;
		text-align: center
	}
}

